<template>
    <div class="field-card-container">
        <div class="field-card-text">
            <h2>{{ fieldName }}</h2>
            <h4 v-if="homeTeams !== null">{{ compileTeams }}</h4>
            <p>{{ text }}</p>
            <h3>Address:</h3>
            <p>{{ address }}</p>
        </div>
        <div class="image-container">
            <img
                :aria-label="`the
            ${fieldName}
            baseball
            field`"
                :src="require(`@/assets/images/${img}`)"
                :alt="`the ${fieldName} baseball field`"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: ["fieldName", "homeTeams", "img", "address", "text"],
    computed: {
        compileTeams() {
            let s = "";
            const length = this.homeTeams?.length;
            this.homeTeams?.forEach((team, i) => {
                return i + 1 === length ? (s += `${team}`) : (s += `${team}, `);
            });
            return length > 1 ? `Home Teams: ${s}` : `Home Team: ${s}`;
        },
    },
};
</script>

<style lang="scss">
@use "../scss/abstracts" as *;
.field-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(scaled(15, 20), 1fr));
    padding: scaled(1, 2);
    background-color: $bg-color-light;
    margin-bottom: scaled(1, 3);

    .field-card-text {
        margin-right: scaled(2, 3);
    }

    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        flex-shrink: 0;
        height: 20rem;
        margin: auto 0;

        img {
            object-fit: cover;
            object-position: center;
            min-height: 100%;
            min-width: 100%;
        }
    }
}
</style>
