<template>
    <h4>{{ heading }}</h4>
    <div v-if="heading === 'Social Media'" class="social-media-container">
        <SVGIcon
            v-for="link in links"
            :key="link.id"
            v-bind="link"
            class="social-icon link"
        />
    </div>

    <ul v-else>
        <li v-for="link in links" :key="link.id">
            <a
                :href="link.href"
                rel="noopener noreferrer"
                target="_blank"
                class="link"
            >
                {{ link.name }}
            </a>
        </li>
    </ul>
</template>
<script>
import SVGIcon from "./SVGIcon.vue";
export default {
    props: ["id", "heading", "links"],
    components: { SVGIcon },
};
</script>
<style lang="scss">
@use "../scss/abstracts" as *;

h4,
ul {
    color: $bg-color-dark;
}

ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    li a {
        font-family: "Roboto", sans-serif;
        color: $team-color-orange;
    }

    li a:hover,
    li a:active {
        cursor: pointer;
        color: $team-color-orange-hover;
    }
}
.social-media-container {
    display: flex;
    gap: scaled(1, 1.2);
    justify-content: center;
    align-items: center;
}
.social-icon {
    max-width: scaled(1.5, 2);
    aspect-ratio: 1/1;
}

// .social-icon:hover,
// .social-icon:active {
//     cursor: pointer;
//     fill: $team-color-orange-hover;
// }
</style>
