<template>
    <div class="success-container">
        <main
            aria-label="main content describing baseball in finland"
            class="section light"
        >
            <HeadingParagraphTextContainer v-bind="successText" />
        </main>
    </div>
</template>

<script>
import { successText } from "../assets/text";
import HeadingParagraphTextContainer from "../components/HeadingParagraphTextContainer.vue";
export default {
    name: "SuccessView",
    data() {
        return {
            successText: successText,
        };
    },
    components: {
        HeadingParagraphTextContainer,
    },
};
</script>

<style lang="scss">
@use "../scss/abstracts/" as *;
</style>
