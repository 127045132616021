<template>
    <form
        aria-label="Contact form to send a message to the helsinki mets"
        action="https://formsubmit.co/77eefe0cb08e5822c566d5242ca9a683"
        method="post"
        class="form-s1"
    >
        <input
            type="hidden"
            name="_next"
            value="https://www.helsinkimets.fi/success.html"
        />
        <input
            type="hidden"
            name="_subject"
            value="Contact form from website"
        />
        <div class="form-item">
            <input
                type="text"
                class="form-input"
                placeholder="name"
                aria-label="name"
                required
                name="Name"
            />
        </div>
        <div class="form-item">
            <input
                type="email"
                class="form-input"
                placeholder="email"
                aria-label="email"
                required
                name="Email"
            />
        </div>
        <div class="form-item">
            <textarea
                class="form-input"
                id="message"
                cols="30"
                rows="5"
                placeholder="message"
                aria-label="message"
                name="Message"
            ></textarea>
        </div>
        <button
            aria-label="button to send message"
            type="submit"
            class="form-button"
        >
            Send
        </button>
    </form>
</template>

<style lang="scss">
@use "../scss/abstracts" as *;
input,
textarea {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;
}
.form-s1 {
    -webkit-appearance: none;
    max-width: rem(500);
    display: flex;
    flex-direction: column;
    padding: rem(10);
    font-family: inherit;

    .form-input {
        width: 100%;
        font-family: "Oswald", sans-serif;
        text-transform: uppercase;
        font-size: $fs7;
        letter-spacing: rem(1.5);
        border: 0;
        padding: rem(10);
        outline: 0;
    }

    .form-input::placeholder {
        color: $bg-color-dark-shade1;
        opacity: 0.5;
    }

    .form-input:focus::placeholder {
        color: $bg-color-dark;
        opacity: 1;
    }

    .form-button {
        width: 50%;
        font-family: "Oswald", sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        font-size: $fs6;
        background-color: $team-color-orange;
        border: none;
        min-height: rem(40);
        border-radius: rem(5);
        color: #fff;
    }

    .form-button:hover,
    .form-button:active {
        cursor: pointer;
        background-color: $team-color-orange-hover;
    }

    .form-item {
        margin-bottom: rem(2);
        &:last-child {
            margin-bottom: 0;
        }
        & textarea {
            resize: none;
        }
    }
}
</style>
