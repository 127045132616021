<template>
    <div class="win-loss-container">
        <div
            aria-label="current season win and loss results for the helsinki mets"
            class="record-container"
        >
            <h3>
                Record | <em>{{ games.year }}</em>
            </h3>
            <h4>{{ games.wins }} : {{ games.losses }}</h4>
        </div>
        <div class="schedule-text-content">
            <h3>Game Schedule</h3>
            <p>
                This is the current schedule for the season. If you'd like to
                come to a game but are unsure about where the field is, please
                visit the
                <router-link
                    @click="scrollToTop"
                    class="link"
                    :to="{ name: 'FieldLocations' }"
                    >field locations</router-link
                >
                page.
            </p>
            <p>
                If a game has a reported score, you can click on the row and be
                taken to the full game stats page (external website).
            </p>
						<Schedule :schedule="games.games"/>
        </div>
    </div>
</template>

<script setup>
import Schedule from "./Schedule.vue";
import { useGamesStore } from "../stores/gamesStore"

const games = useGamesStore()

const scrollToTop = () => {
	window.scrollTo(0,0)
}

</script>

<style lang="scss">
@use "../scss/abstracts/" as *;

.win-loss-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: scaled(1, 2);
}

.record-container {
    h3 {
        color: $bg-color-light;
        font-size: scaled(1.2, 2);
    }
    h4 {
        color: $bg-color-light;
        font-size: scaled(2, 3);
    }
}
.schedule-text-content {
    h3,
    p {
        color: $bg-color-light;
    }
}
</style>
