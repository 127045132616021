export const gameData = {
year: 2023,
games: [
		{
			id: 1,
			homeTeam: "Saku",
			awayTeam: "Mets",
			scoreHome: 7,
			scoreAway: 0,
			statLink: "https://finland.wbsc.org/en/events/2023-superbaseball-2023/schedule-and-results/box-score/119732",
			gameDate: "21-5",
			gameTime: "13:00",
		},
		{
			id: 2,
			homeTeam: "Mets",
			awayTeam: "Expos",
			scoreHome: 2,
			scoreAway: 21,
			statLink: "https://finland.wbsc.org/en/events/2023-superbaseball-2023/schedule-and-results/box-score/119735",
			gameDate: "26-5",
			gameTime: "18:00",
		},
		{
			id: 3,
			homeTeam: "Tigers",
			awayTeam: "Mets",
			scoreHome: 18,
			scoreAway: 3,
			statLink: "https://finland.wbsc.org/en/events/2023-superbaseball-2023/schedule-and-results/box-score/119737",
			gameDate: "31-5",
			gameTime: "18:00",
		},
		{
			id: 4,
			homeTeam: "Expos",
			awayTeam: "Mets",
			scoreHome: 14,
			scoreAway: 1,
			statLink: "https://finland.wbsc.org/en/events/2023-superbaseball-2023/schedule-and-results/box-score/119738",
			gameDate: "4-6",
			gameTime: "13:00",
		},
		{
			id: 5,
			homeTeam: "Mets",
			awayTeam: "Tigers",
			scoreHome: 5,
			scoreAway: 16,
			statLink: "https://finland.wbsc.org/en/events/2023-superbaseball-2023/schedule-and-results/box-score/119740",
			gameDate: "7-6",
			gameTime: "18:30",
		},
		{
			id: 6,
			homeTeam: "Mets",
			awayTeam: "Puumat",
			scoreHome: 13,
			scoreAway: 31,
			statLink: "https://finland.wbsc.org/en/events/2023-superbaseball-2023/schedule-and-results/box-score/119742",
			gameDate: "12-6",
			gameTime: "18:30",
		},
		{
			id: 7,
			homeTeam: "Mets",
			awayTeam: "Expos",
			scoreHome: 7,
			scoreAway: 19,
			statLink: "https://finland.wbsc.org/en/events/2023-superbaseball-2023/schedule-and-results/box-score/119745",
			gameDate: "9-7",
			gameTime: "15:30",
		},
		{
			id: 8,
			homeTeam: "Tigers",
			awayTeam: "Mets",
			scoreHome: 13,
			scoreAway: 12,
			statLink: "https://finland.wbsc.org/en/events/2023-superbaseball-2023/schedule-and-results/box-score/119747",
			gameDate: "16-7",
			gameTime: "13:00",
		},
		{
			id: 9,
			homeTeam: "Puumat",
			awayTeam: "Mets",
			scoreHome: 15,
			scoreAway: 3,
			statLink: "https://finland.wbsc.org/en/events/2023-superbaseball-2023/schedule-and-results/box-score/119748",
			gameDate: "23-7",
			gameTime: "13:00",
		},
		{
			id: 10,
			homeTeam: "Mets",
			awayTeam: "Puumat",
			scoreHome: 11,
			scoreAway: 13,
			statLink: "https://finland.wbsc.org/en/events/2023-superbaseball-2023/schedule-and-results/box-score/119751",
			gameDate: "4-8",
			gameTime: "18:00",
		},
		{
			id: 11,
			homeTeam: "Mets",
			awayTeam: "Tigers",
			scoreHome: 2,
			scoreAway: 8,
			statLink: "https://finland.wbsc.org/en/events/2023-superbaseball-2023/schedule-and-results/box-score/119752",
			gameDate: "9-8",
			gameTime: "18:00",
		},
		{
			id: 12,
			homeTeam: "Expos",
			awayTeam: "Mets",
			scoreHome: 0,
			scoreAway: 0,
			statLink: null,
			gameDate: "18-8",
			gameTime: "18:00",
		},
	],
};
