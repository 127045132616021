<template>
    <main class="fields-section">
        <FieldDetailsBlock
            v-for="field in fieldList"
            :key="field.id"
            v-bind="field"
        />
    </main>
    <aside><HeadingParagraphTextContainer v-bind="aboutFieldData" /></aside>
</template>

<script>
import HeadingParagraphTextContainer from "../components/HeadingParagraphTextContainer.vue";
import FieldDetailsBlock from "../components/FieldDetailsBlock.vue";
import { fieldLocations } from "../assets/fields";
export default {
    data() {
        return {
            aboutFieldData: {
                heading: fieldLocations.heading,
                paragraphs: fieldLocations.paragraphs,
            },
            fieldList: fieldLocations.fields,
        };
    },
    components: { HeadingParagraphTextContainer, FieldDetailsBlock },
};
</script>

<style lang="scss">
@use "../scss/abstracts" as *;
.fields-section {
    display: flex;
    flex-direction: column;
}

aside {
    padding: scaled(1, 2);
    margin-bottom: scaled(1, 3);
    & > * {
        color: white;
    }
    p {
        color: $bg-color-light;
    }
}
</style>
