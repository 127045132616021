<template>
    <div class="contact-text">
        <HeadingParagraphTextContainer v-bind="contactText" />
    </div>
    <ContactForm />
</template>
<script>
import HeadingParagraphTextContainer from "../components/HeadingParagraphTextContainer.vue";
import ContactForm from "../components/ContactForm.vue";
import { contactText } from "../assets/text";
export default {
    data() {
        return {
            contactText: contactText,
        };
    },
    components: { HeadingParagraphTextContainer, ContactForm },
};
</script>
<style lang="scss">
.contact-text {
    margin-bottom: 2rem;
}
</style>
