export const routesLeague = {
    id: 1,
    heading: "External Links",
    links: [
        {
            id: 1,
            name: "SuperBaseball",
            href: "https://finland.wbsc.org/en/calendar",
            svgID: null,
            svgFile: null,
        },
        {
            id: 2,
            name: "League Standing",
            href: "https://finland.wbsc.org/en/events/2023-superbaseball-2023/standings",
            svgID: null,
            svgFile: null,
        },
        {
            id: 3,
            name: "League Schedule",
            href: "https://finland.wbsc.org/en/events/2023-superbaseball-2023/schedule-and-results",
            svgID: null,
            svgFile: null,
        },
        {
            id: 4,
            name: "League Teams",
            href: "https://finland.wbsc.org/en/events/2023-superbaseball-2023/teams",
            svgID: null,
            svgFile: null,
        },
    ],
};
