<template>
    <section>
        <h2>{{ heading }}</h2>
        <p v-for="(p, index) in paragraphs" :key="index">{{ p }}</p>
    </section>
</template>

<script>
export default {
    props: ["heading", "paragraphs"],
};
</script>
