<template>
    <div aria-label="league links">
        <FooterBlock v-bind="leagueInfo" />
    </div>
    <div aria-label="social media links">
        <FooterBlock v-bind="socialInfo" />
    </div>
</template>
<script>
import { routesLeague } from "../assets/RoutesLeague";
import { routesSocialMedia } from "../assets/RoutesSocialMedia";
import FooterBlock from "./FooterBlock.vue";
export default {
    data() {
        return {
            leagueInfo: routesLeague,
            socialInfo: routesSocialMedia,
        };
    },
    components: { FooterBlock },
};
</script>

<style lang="scss">



</style>
