import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import FieldLocationsView from "../views/FieldLocationsView.vue";
import SuccessView from "../views/SuccessView.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomeView,
    },
    {
        path: "/field-locations",
        name: "FieldLocations",
        component: FieldLocationsView,
    },
    {
        path: "/success",
        name: "Success",
        component: SuccessView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
