<template>
    <svg>
        <a
            :aria-label="`${name} link`"
            :href="href"
            target="_blank"
            rel="noopener noreferrer"
        >
            <use
                :xlink:href="
                    require(`@/assets/images/${svgFile}`) + `#${svgID}`
                "
            ></use>
        </a>
    </svg>
</template>

<script>
export default {
    props: ["name", "svgID", "href", "svgFile"],

    // computed: {
    //     computedClass() {
    //         return this.svgID;
    //     },
    // },
};
</script>

<style lang="scss"></style>
