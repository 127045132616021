export const routesSocialMedia = {
    id: 1,
    heading: "Social Media",
    links: [
        {
            id: 1,
            name: "facebook",
            href: "https://www.facebook.com/HelsinkiMets",
            svgID: "facebook",
            svgFile: "socialMedia-icons.svg",
        },
        {
            id: 2,
            name: "instagram",
            href: "https://www.instagram.com/helsinki_mets/",
            svgID: "instagram",
            svgFile: "socialMedia-icons.svg",
        },
        {
            id: 3,
            name: "twitter",
            href: "https://twitter.com/helsinkimets",
            svgID: "twitter",
            svgFile: "socialMedia-icons.svg",
        },
    ],
};
