<template>
    <div class="homepage-container">
        <main
            aria-label="main content describing baseball in finland"
            class="section light"
        >
            <MainSection />
        </main>
        <section
            aria-label="contact section"
            class="contact-section section light"
        >
            <ContactSection />
        </section>
        <section
            aria-label="about section for the helsinki mets"
            class="about-section section light"
        >
            <HeadingParagraphTextContainer v-bind="aboutText" />
        </section>
        <!-- <section
            aria-label="Mets win loss and schedule section"
            class="win-loss-section section"
        >
            <Standing />
        </section> -->
    </div>
</template>

<script>
import { aboutText } from "../assets/text";
import Standing from "../components/Standing.vue";
import MainSection from "../components/MainSection.vue";
import ContactSection from "../components/ContactSection.vue";
import HeadingParagraphTextContainer from "../components/HeadingParagraphTextContainer.vue";
export default {
    name: "HomeView",
    data() {
        return {
            aboutText: aboutText,
        };
    },
    components: {
        Standing,
        MainSection,
        ContactSection,
        HeadingParagraphTextContainer,
    },
};
</script>

<style lang="scss">
@use "../scss/abstracts/" as *;

.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: scaled(1, 3);

    .contact-section {
        width: 100%;
    }
}

.light {
    background-color: $bg-color-light;
}

.section {
    padding: scaled(1, 2) scaled(1, 1.1);
}

.win-loss-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(scaled(15, 23), 1fr));
    gap: 2rem;
    padding: 0 scaled(1, 1.2);
}
</style>
