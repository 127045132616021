import { defineStore } from 'pinia'
import { gameData } from '@/assets/games'

export const useGamesStore = defineStore('gamesStore', {
	state: () => ({
		year: gameData.year,
		games: gameData.games
	}),
	getters: {
		wins: (state) => {
			return state.games.
				filter(game =>
					(game.homeTeam.toLowerCase() === "mets" && game.scoreHome > game.scoreAway) ||
					(game.awayTeam.toLowerCase() === "mets" && game.scoreAway > game.scoreHome)).
				reduce((acc, game) => acc + 1, 0)
		},
		losses: (state) => {
			return state.games.
				filter(game =>
					(game.homeTeam.toLowerCase() === "mets" && game.scoreHome < game.scoreAway) ||
					(game.awayTeam.toLowerCase() === "mets" && game.scoreAway < game.scoreHome)).
				reduce((acc, game) => acc + 1, 0)
		},
	}
})