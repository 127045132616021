export const fieldLocations = {
    heading: "About the locations",
    paragraphs: [
        `Why are most of the fields NOT traditional baseball fields? Although Finland has a national team for this style of baseball, Finland has its own variation of the sport.`,
        `Pesäpallo, directly translated to nest ball, is the country's main style of this sport. Due to the lack of popularity in baseball, there hasn't been an incredible amount of funding towards the sport here to invest in dedicated facilities.`,
        `Despite that, there has been an increasing amount of attention to this sport due to its international appeal as well as many talented prospects that have potential to be successfull abroad.`,
        `Additionally, many individuals have gone above and beyond in order to make this sport more popular in Finland. Our hats definitly go off to them!`,
    ],
    fields: [
        {
            id: 1,
            fieldName: "Myllypuro, Helsinki",
            homeTeams: ["Expos", "Mets", "Puumat"],
            img: "helsinki-min.png",
            address: "Jäätanssipolku 1, 00900 Helsinki",
            text: "The opening of the field was held in the summer of 2019 and it was completed in its entirety for the 2020 season. The surface material of the field is sand and natural grass. The ground has fixed backstops and side fences. The outfield fence must be erected separately on gameday. The field has dugouts, a bullpen, a batting cage and a storage room locker. Stadium seating is available behind the backstop.",
        },
        {
            id: 2,
            fieldName: "Pirates Stadium, Lahti",
            homeTeams: ["Pirates"],
            img: "lahti-min.png",
            address:
                "The field is located at the end of Ristkarinkatu in Lahti.",
            text: "The Lahti Pirates Stadium is the home of the Pirates and was the first field in Finland to be completed exclusively for baseball. The surface material of the field is artificial grass and sand. The field has fixed fences and dugouts for the home and away team.",
        },
        {
            id: 3,
            fieldName: "Tampere",
            homeTeams: ["Tigers"],
            img: "tampere-min.png",
            address: "Ilmailunkatu 11, 33900 Tampere",
            text: "The field in Tampere is a mixure of crushed stone and sand featuring a separately assembled backstop.",
        },
        {
            id: 4,
            fieldName: "Käpylä, Helsinki",
            homeTeams: null,
            img: "kaypyla-min.png",
            address:
                "The Käpylä sand field is located in the Käpylä sports park at Mäkelänkatu 72, 00610 Helsinki.",
            text: "The field surface is mixure of small stone and sand with a fixed backstop and side fences.",
        },
        {
            id: 5,
            fieldName: "Kilo, Espoo",
            homeTeams: null,
            img: "kilo-min.png",
            address: "Aspelintie 3, 02630 Espoo",
            text: "The field in Kilo is a school field with smaller dimensions in terms for baseball usage. Lefties certainly have an advantage for home runs.",
        },
        {
            id: 6,
            fieldName: "Turku",
            homeTeams: null,
            img: "turku-min.png",
            address: "Lemminkäisenkatu 11, 20520 Turku",
            text: "The field in Turku has a stone ash surface.",
        },
    ],
};
