export const introText = {
    heading: "North American Style Baseball in Finland!",
    paragraphs: [
        `Yes, you read that right! What an amazing opportunity to be had in Finland! If you've moved here from another country and wanted to play the game you love, it can be done!`,
        `The Helsinki Mets are part of the Superbaseball league in Finland. The league is
        comprised of four teams this season. The Mets, Expos,
        Puumat, and Tigers.`,
        `Would you like to play with us? Or just know more
        about the league here? What's involved? Any
        questions you have for us, fill out the contact form
        below.`,
    ],
};

export const aboutText = {
    heading: "Who are the Helsinki Mets?",
    paragraphs: [
        `The Mets baseball club started in 2012 and played two seasons in the Suomi-sarja league (now Fat Lizard league), winning the Championship in 2013.`,
        `The team has participated in Finland’s top league, SM-sarja (now called Superbaseball), since 2014 and won their first SM Championship in 2018.`,
        `The players enjoy friendships that extend beyond team events and playing baseball during the summer. Quite a few of them enjoy Super Bowl parties, going out for drinks, or simply hanging out at each others houses watching MLB.`,
    ],
};

export const contactText = {
    heading: "Join the Mets!",
    paragraphs: [
        `The new season is just getting underway and the Mets are actively looking for new talent.`,
				`Reach out to use via this form and we'll get back to you ASAP.`,
    ],
};

export const successText = {
    heading: "Message Successfully Sent!",
    paragraphs: [
        `Thank you for taking the time to contact the Mets. We will get
    back to you as soon as we can.`,
        `Please use any of the links around this page to return to normal
        browsing.`,
    ],
};
