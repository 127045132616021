<template>
    <body>
        <div class="content-container">
            <header
                class="header-section section"
                aria-label="Welcome to the helsinki mets webpage"
            >
                <h1 aria-label="Helsinki Mets">
                    <svg class="mets-title">
                        <use
                            :xlink:href="
                                require(`@/assets/images/logo-icons.svg`) +
                                `#mets-header-text`
                            "
                        ></use>
                    </svg>
                </h1>
                <nav>
                    <router-link :to="{ name: 'Home' }">Home</router-link>
                    |
                    <router-link :to="{ name: 'FieldLocations' }"
                        >Field Locations</router-link
                    >
                </nav>
            </header>
            <router-view />
            <footer class="section"><FooterSection /></footer>
        </div>
    </body>
</template>

<script>
import FooterSection from "./components/FooterSection.vue";
export default {
    components: { FooterSection },
};
</script>

<style lang="scss">
@use "./scss/abstracts" as *;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&family=Oswald:wght@700&family=Quattrocento+Sans&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap");
@import "./scss/reset.scss";

@font-face {
    font-family: "astrudregular";
    src: url("/src/assets/fonts/astrud-webfont.woff2") format("woff2"),
        url("/src/assets/fonts/astrud-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

h2,
h3,
h4 {
    font-family: "Oswald", sans-serif;
    margin-bottom: scaled(1, 1.2);
}

p {
    font-family: "Montserat", sans-serif;
    margin-bottom: $fs7;
    &:last-child {
        margin-bottom: 0;
    }
}

body {
    background-color: $bg-color-dark;
}

nav {
    display: flex;
    justify-content: center;
    gap: scaled(1, 2);
    color: $bg-color-light;
}

nav a {
    font-family: "Roboto", sans-serif;
    color: $team-color-orange;
}

nav a:hover,
nav a:active {
    cursor: pointer;
    color: $team-color-orange-hover;
}

nav a.router-link-exact-active {
    color: $team-color-orange-hover;
}

.header-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link {
    font-family: "Roboto", sans-serif;
    color: $team-color-orange;
    fill: $team-color-orange;
}

.link:hover,
.link:active {
    cursor: pointer;
    color: $team-color-orange-hover;
    fill: $team-color-orange-hover;
}

.content-container {
    max-width: 800px;
    margin: 0 auto;
}

footer {
    margin-bottom: scaled(1.5, 2);
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 500px) {
    footer {
        flex-direction: row;
        justify-content: center;
        gap: 5rem;
    }
}
</style>
