<template>
    <section class="hero-section">
        <div class="hero-images-container">
            <HelsinkiMetsLogo />
            <BaseBallFinlandLogo />
        </div>
        <div class="hero-text-container">
            <HeadingParagraphTextContainer v-bind="introText" />
        </div>
    </section>
</template>

<script>
import { introText } from "../assets/text";
import BaseBallFinlandLogo from "./BaseBallFinlandLogo.vue";
import HelsinkiMetsLogo from "./HelsinkiMetsLogo.vue";
import HeadingParagraphTextContainer from "./HeadingParagraphTextContainer.vue";

export default {
    data() {
        return {
            introText: introText,
        };
    },
    components: {
        BaseBallFinlandLogo,
        HelsinkiMetsLogo,
        HeadingParagraphTextContainer,
    },
};
</script>

<style lang="scss">
@use "../scss/abstracts" as *;
.hero-images-container {
    display: flex;
    justify-content: center;
    margin-bottom: scaled(1.5, 3);
    filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.2));
}
</style>
